<template>
  <v-row class="px-10" dense>
    <v-col  cols="12" md="2" class="pt-4 pb-8" align="center">
      <v-avatar
        class="u-cursor-pointer"
        size="80"
        color="grey lighten-4 elevation-2"
      >
        <v-img
          v-if="imageProfile"
          @mouseover="showClearBtn = true"
          @mouseleave="showClearBtn = false"
          :src="imageProfile"
          alt="foto de perfil"
        >
          <transition name="fadeIn">
            <v-icon
              v-show="showClearBtn"
              @click="clearFileField"
              color="white"
              class="primary"
              v-text="'fas fa-times'"
            />
          </transition>
        </v-img>
        <v-img
          v-else-if="user.image_profile"
          @mouseover="showClearBtn = true"
          @mouseleave="showClearBtn = false"
          :src="`${$store.state.imagePath + user.image_profile}`"
          alt="foto de perfil"
        >
          <transition name="fadeIn">
            <v-icon
              v-show="showClearBtn"
              @click="clearFileField"
              color="white"
              class="primary"
              v-text="'fas fa-times'"
            />
          </transition>
        </v-img>
        <v-icon
          v-else
          @click="onButtonClick"
          color="primary"
          v-text="'fas fa-camera'"
        />
      </v-avatar>
      <input
        ref="uploader"
        id="image_profile"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileChanged"
      />
    </v-col>
    <v-col cols="12" md="10">
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="user.name"
            label="Nome*"
            placeholder="Digite o nome"
            :rules="[() => !!user.name || 'Este campo é obrigatório']"
            outlined
            rounded
            dense
          />
          <v-text-field
            v-model="user.birthday"
            label="Data de Nascimento"
            placeholder="XX/XX/XXXX"
            outlined
            v-mask="'##/##/####'"
            rounded
            dense
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="user.email"
            label="Email*"
            type="email"
            placeholder="exemplo@gmail.com"
            :rules="[
              () => !!user.email || 'Este campo é obrigatório',
              rules.email,
            ]"
            outlined
            rounded
            dense
          />
          <v-text-field
            v-model="user.contact"
            label="Telefone"
            placeholder="(xx)x.xxxx-xxxx"
            outlined
            v-mask="['(##) ####-####', '(##) #####-####']"
            type="tel"
            rounded
            dense
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data: () => ({
    showClearBtn: false,
    menu: false,
    activePicker: null,
    imageProfile: "",
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Insira um email válido";
      },
    },
  }),
  computed: {
    birthday: function () {
      if (this.user.birthday != null) {
        let BR_dateFormat = this.user.birthday.split("-");
        BR_dateFormat = BR_dateFormat.reverse();
        BR_dateFormat = BR_dateFormat.join("/");
        return BR_dateFormat;
      } else {
        return "";
      }
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.user.image_profile = e.target.files[0];
      this.imageProfile = URL.createObjectURL(e.target.files[0]);
    },
    clearFileField() {
      this.user.image_profile = null;
      this.imageProfile = null;
    },
  },
};
</script>

<style lang="scss">
  .fadeIn-enter,
  .fadeIn-leave-to {
    opacity: 0;
  }
  .fadeIn-enter-active,
  .fadeIn-leave-active {
    transition: all 0.1s;
  }
  .fadeIn-enter-to,
  .fadeIn-leave {
    opacity: 1;
  }
</style>